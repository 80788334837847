
.logo-dark0 {
    fill: #E31837;
   
}
.logo-light0 {    
    fill: #ffffff;
}

.logo-dark1 {
    fill: none;
    stroke: #E13A3E;
    stroke-width: 1;
}

.logo-light1 {
    fill: none;
    stroke: #E13A3E;
    stroke-width: 1;
}

.logo-dark2 {    
}

.logo-light2 {
    fill: #ffffff;
}

.view-size {
    width:100px;  
    
}

.app-title {
    font-weight:bold;
    margin-left: 5px;
    font-style:italic;
}

